import React from 'react';
import Layout from '../layouts';

import HeroSecion from '../components/HeroSecion';
import IntroSection from '../components/IntroSection';
import DemoSection from '../components/DemoSection';
import FeatureSection from '../components/FeatureSection';
import AboutSection from '../components/AboutSection';
import CooperatorSection from '../components/CooperatorSection';
import QuestionAnswerSection from '../components/QuestionAnswerSection';
import AddMeiyuSection from '../components/AddMeiyuSection';
import Footer from '../components/Footer';

export default ({ location }) => {
  return (
    <Layout location={location}>
      <HeroSecion />
      <IntroSection />
      <DemoSection />
      <FeatureSection />
      <AboutSection />
      <CooperatorSection />
      <QuestionAnswerSection />
      <AddMeiyuSection />
      <Footer />
    </Layout>
  );
};
