import React from 'react';

import useIntlT from '../utils/useIntlT';

import chatCheckSvg from '../assets/features/chat-check.svg';
import callCheckSvg from '../assets/features/call-check.svg';
import idCheckSvg from '../assets/features/id-check.svg';
import personalInfoSvg from '../assets/features/personal-info.svg';

import styles from './feature-section.module.css';

export default () => {
  const t = useIntlT();

  return (
    <div className="max-w-screen-lg mx-auto p-4 md:p-8">
      <h2 className="mb-8 text-3xl font-bold text-center">{t`featureTitle`}</h2>
      <div className={`${styles.features} md:grid md:grid-cols-4`}>
        <div className={`${styles.item} p-2`}>
          <img
            className="inline-block md:block mb-4 mr-2 w-10"
            alt={t`chatCheckTitle`}
            src={chatCheckSvg}
          />
          <h4
            className="inline-block md:block my-2 text-lg font-normal"
            dangerouslySetInnerHTML={{ __html: t`chatCheckTitle` }}
          />
          <p className="my-1">{t`chatCheckDescription`}</p>
          <p className="hidden md:block mt-6 text-xs">{t`chatCheckAppendix`}</p>
        </div>
        <div className={`${styles.item} p-2`}>
          <img
            className="inline-block md:block mb-4 mr-2 w-10"
            alt={t`callCheckTitle`}
            src={callCheckSvg}
          />
          <h4
            className="inline-block md:block my-2 text-lg font-normal"
            dangerouslySetInnerHTML={{ __html: t`callCheckTitle` }}
          />
          <p className="my-1">{t`callCheckDescription`}</p>
        </div>
        <div className={`${styles.item} p-2`}>
          <img
            className="inline-block md:block mb-4 mr-2 w-10"
            alt={t`idCheckTitle`}
            src={idCheckSvg}
          />
          <h4
            className="inline-block md:block my-2 text-lg font-normal"
            dangerouslySetInnerHTML={{ __html: t`idCheckTitle` }}
          />
          <p className="my-1">{t`idCheckDescription`}</p>
        </div>
        <div className={`${styles.item} p-2`}>
          <img
            className="inline-block md:block mb-4 mr-2 w-10"
            alt={t`personalInfoTitle`}
            src={personalInfoSvg}
          />
          <h4
            className="inline-block md:block my-2 text-lg font-normal"
            dangerouslySetInnerHTML={{ __html: t`personalInfoTitle` }}
          />
          <p className="my-1">{t`personalInfoDescription`}</p>
        </div>
      </div>
    </div>
  );
};
